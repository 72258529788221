import React, { useState, useEffect } from 'react';
import { Document, Page } from 'react-pdf';
import { createWorker } from 'tesseract.js';


const ReadPdf = () => {

    const [text, setText] = useState('');

    useEffect(() => {
      const worker = createWorker({
        logger: (m) => console.log(m), // Optional logger
      });
  
      const doOCR = async (pdfUrl) => {
        await worker.load();
        await worker.loadLanguage('eng');
        await worker.initialize('eng');
        const { data } = await worker.recognize(pdfUrl);
        setText(data.text);
        await worker.terminate();
      };
  
      doOCR("../pdf/read.pdf"); // Replace with the actual path or URL of your PDF file
  
      // Clean up the worker when the component unmounts
      return () => {
        worker.terminate();
      };
    }, []);

    return (
        <div>


            <Document   file="../pdf/read.pdf" onLoadSuccess={null}>
            <Page pageNumber={1} />
                {/* <Page pageNumber={currentPage} /> */}
            </Document>
            <div>
                 <h3>Extracted Text:</h3>
                 <pre>{text}</pre>
            </div>
        </div>
    );

};


export default ReadPdf;
