
import { useLocation } from "react-router-dom";
const Nav = () => {
    const location = useLocation();

    return (
        <>
            <div className="container">
                <div className="row d-flex justify-content-center">
                    <div className="col-lg-8 ">
                        <img src="/images/logo.jpg" />
                    </div>
                </div>
            </div>
           
                <div className="container-fluid" style={{ "backgroundColor": "black" }}>

                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        {/*  <a className="navbar-brand" href="index.html"><img src="images/logo.png" /></a> */}
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav mr-auto">
                                <li className={`nav-item ${location.pathname === '/' ? 'active' : ''}`}>
                                    <a className="nav-link" href="/">Home</a>
                                </li>
                                <li className={`nav-item ${location.pathname.includes('/About') ? 'active' : ''}`}>
                                    <a className="nav-link" href="/About">About</a>
                                </li>
                                <li className={`nav-item ${location.pathname.includes('/Information') ? 'active' : ''}`}>
                                    <a className="nav-link" href="/Information">Information Center</a>
                                </li>
                                <li className={`nav-item ${location.pathname.includes('/Events') ? 'active' : ''}`}>
                                    <a className="nav-link" href="/Events">Events</a>
                                </li>
                                <li className={`nav-item ${location.pathname.includes('/Notices') ? 'active' : ''}`}>
                                    <a className="nav-link" href="/Notices">Notices</a>
                                </li>
                                <li className={`nav-item ${location.pathname === '/Photo' ? 'active' : ''}`}>
                                    <a className="nav-link" href="/Photo">Photo gallery</a>
                                </li>
                                <li className={`nav-item ${location.pathname === '/Contact' ? 'active' : ''}`}>
                                    <a className="nav-link" href="/Contact">Contact Us</a>
                                </li>


                            </ul>

                        </div>

                        
                        <a href="https://www.facebook.com/profile.php?id=100073675188554"  style={{"font-size": "xx-large","color": "white", "margin-right": "10px"}}><i className="fa fa-facebook"  aria-hidden="true"></i></a>
                        <a href="https://youtube.com/@TheSBVM"  style={{"font-size": "xx-large","color": "white","margin-right": "7px"}}><i className="fa fa-youtube" aria-hidden="true"></i></a>

                    </nav>
                </div>
           
        
                           
                </>

           

            
        
    );
}
export default Nav;