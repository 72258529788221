const Meet=()=>{
return(
    <div className="studies_section layout_padding">
    <div className="container">
       <div className="row">
          <div className="col-sm-12">
             <h1 className="studies_taital">Our Mentors</h1>
          </div>
       </div>
       <div className="studies_section_2">
          <div className="row">
             <div className="col-md-4">
                <div className="hover01 column">
                   <figure><img src="images/pic1.jpg"/></figure>
                </div>
                <div className="studies_box">
                   <h3 className="introduction_text">LT. SHRI RAJKRIPAL JI</h3>
                   <p style={{"text-align": "center"}}>Founder</p>
                </div>
                
             </div>
             <div className="col-md-4">
                <div className="hover01 column">
                   <figure><img src="images/pic3.jpg"/></figure>
                </div>
                <div className="studies_box">
                   <h3 className="introduction_text">SMT. SWATI GARG</h3>
                   <p style={{"text-align": "center"}}>President (SBVM)</p>
                </div>
               
             </div>
             <div className="col-md-4">
                <div className="hover01 column">
                   <figure><img src="images/pic2.jpg"/></figure>
                </div>
                <div className="studies_box">
                   <h3 className="introduction_text">SMT.POONAM AGARWAL</h3>
                   <p style={{"text-align": "center"}}>Manager (SBVM)</p>
                </div>
               
             </div>
          </div>
       </div>
    </div>
 </div>
);
}

export default Meet;