import Gmap from "./Gmap";
import Nav from "./nav";
import Footer from "./Footer";
import Enquiry from "./Enquiry";
const Contact = () => {

    return (
        <>
            <Nav></Nav>
            <div className="news_section layout_padding" style={{"marginBottom": "2em"}}>
                <div className="container">
                <h1>Contact US</h1>
                        <hr></hr>
                    <div className="row">
                        <div className="col-md-8">
                            <Gmap></Gmap>
                        </div>
                        <div className="col-md-4">
                            <h2>Phone number</h2>
                            <p> 0122-2317323, 2317220</p>

                            <h2>E-mail</h2>
                            <p>  sbvmhapur@gmail.com</p>
                           {/*  <p>  817130223</p> */}
                        </div>
                        <div className="col-md-12">
                            <br></br>
                            <br></br>
                            <h2>Enquiry Form</h2>
                            <hr></hr>
                            <Enquiry></Enquiry>

                        </div>
                    </div>

                </div>
            </div>
            <Footer></Footer>
        </>
    );
}
export default Contact;