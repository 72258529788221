import React from 'react';
import Slider from 'react-slick';
import { useState, useEffect, useRef } from "react";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';


const BlogSlider = () => {

    const [data, setData] = useState("");
    const [currentIndex, setCurrentIndex] = useState(0);
    const sliderRef = useRef(null);

    useEffect(() => {
        setTimeout(() => {
        const url = "https://sbvmapi.uniservedata.in/api/content?nameType=Latest News";
        GetData(url);
        },2000);
    }, []);

    useEffect(() => {
        if (data.length > 0 && sliderRef.current) {
          const interval = setInterval(() => {
            const slideCount = data.length;
            const nextIndex = currentIndex < slideCount - 1 ? currentIndex + 1 : 0;
            setCurrentIndex(nextIndex);
            sliderRef.current.slickGoTo(nextIndex);
          }, 6000);
    
          return () => {
            clearInterval(interval);
          };
        }
      }, [currentIndex, data]);

    const GetData = async (url) => {
        const data = await fetch(url);
        const res = await data.json();
        setData(res.content_data);
        console.log(res.content_data);
    }

    const getImg = (url) => {
        const parts = url.split("\/");
        const newurl = "https://sbvmapi.uniservedata.in/image/" + parts[1];
        return newurl;
    }

    const settings = {
        dots: false,
        infinite: true,
        speed: 1500,
        slidesToShow: 1,
        slidesToScroll: 1,
       /*  autoplay: true,
        autoplaySpeed: 5000,  */
        initialSlide: currentIndex,
        afterChange: (index) => setCurrentIndex(index),
         
    };


    return (
        <div className="news_section layout_padding">
            <div className="container">
                <h1 className="news_taital">Recent News</h1>
                <div className="news_section_2">
                    <div className="news_box">
                        <Slider {...settings} ref={sliderRef}>
                            {data.length > 0 && data.map((slide, index) => <>        <div key={index}>
                            <div className="news_img image-container"> 
                                <img src={getImg(slide.img)} alt={`Slide ${index + 1}`} />
                                </div>
                                <br></br>
                                <h2>{slide.heading}</h2>
                                <p className="card-text" dangerouslySetInnerHTML={{ __html: slide.textData }}></p>
                            </div>
                            </>

                            )}
                        </Slider>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BlogSlider;
