import Gmap from "./Gmap";
const Footer = () => {
   return (
      <div className="footer_section layout_padding">
         <div className="container" style={{"font-variant": "small-caps", "font-size": "initial"}}>           
          <div className="row">
               <div className="col-md-4" style={{"color": "white"}}>
                  <Gmap></Gmap>
               </div>
               <div className="col-md-4" style={{"color": "white"}}>
                  <h2 style={{"color": "white"}}>Phone number</h2>
                  <p> 0122-2317323, 2317220</p>
                  
                  <h2 style={{"color": "white"}}>E-mail</h2>
                  <p>  sbvmhapur@gmail.com</p>
                  {/* <p>  817130223</p>*/}

               </div>
               <div className="col-md-4" style={{"color": "white"}}>
                  <h2 style={{"color": "white"}}>Quick Links</h2>
                 
               <a className="nav-link footerlink" href="/">Home</a>
               <a className="nav-link footerlink" href="../About">About</a>
               <a className="nav-link footerlink" href="../Information">Information Center</a>
               <a className="nav-link footerlink" href="../Events">Events</a>
               <a className="nav-link footerlink" href="../Notices">Notices</a>
               <a className="nav-link footerlink" href="../Photo">Photo gallery</a>
               <a className="nav-link footerlink" href="../Contact">Contact Us</a>

               </div>

                 <div className="social_icon">
                  <ul>
                     <li><a href="https://www.facebook.com/profile.php?id=100073675188554" ><i className="fa fa-facebook" aria-hidden="true"></i></a></li>                     
                     <li><a href="https://youtube.com/@TheSBVM" ><i className="fa fa-youtube" aria-hidden="true"></i></a></li>
                  </ul>
                  
               </div> 
               <div className="col-lg-12">
                
                  <p className="footerp">Design and Developed by Akhil bansal-SBVM Hapur 2023</p>
               </div>
            </div>
         </div>
      </div>
   );
}
export default Footer;