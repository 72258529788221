
const NavCard = () => {
    return (
        <div className="container">
            <div className="box_section">
                <div className="online_box">
                    <div className="online_box_left">
                        <div className="online_box_main">
                            <div className="box_left">
                                <div className="right_arrow"><i className="fa fa-arrow-right"></i></div>
                            </div>
                            <div className="box_right">
                                <p className="book_text"></p>
                                <a href="../Photo?tag=Video"> <h4 className="appoinment_text">Infrastructure</h4></a>
                            </div>
                        </div>
                    </div>
                    <div className="online_box_left active">
                        <div className="online_box_main">
                            <div className="box_left">
                                <div className="right_arrow"><i className="fa fa-arrow-right"></i></div>
                            </div>
                            <div className="box_right" >
                                <a href="https://sbvmpayfees.stridesoftech.in/">
                                    <p className="book_text active"></p>
                                    <h4 className="appoinment_text active"> Submit Fee</h4></a>
                            </div>
                        </div>
                    </div>
                    <div className="online_box_left">
                        <div className="online_box_main">
                            <div className="box_left">
                                <div className="right_arrow"><i className="fa fa-arrow-right"></i></div>
                            </div>
                            <div className="box_right">
                                <a href="../pdf/read.pdf" target="_blank">
                                    <p className="book_text"></p>
                                    <h4 className="appoinment_text">Our Achievements</h4></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}
export default NavCard;