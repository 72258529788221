import React from 'react';
import Slider from 'react-slick';

const VideoSlider = () => {
  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  return (
    <Slider {...settings}>
     <div className="slider-item">
        
        <video autoPlay loop muted style={{ width: '100%', height: '500px', objectFit: 'cover' }} className="slider-video">
          <source src="./images/video.mp4" type="video/mp4" />
        </video>      
        </div>
      

      {/* <div>
        <video autoPlay loop muted style={{ width: '100%', height: '100%', objectFit: 'cover' }}>
          <source src="path_to_video_2.mp4" type="video/mp4" />
        </video>
      </div> */}
      {/* Add more video slides as needed */}
    </Slider>
  );
};

export default VideoSlider;
