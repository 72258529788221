import logo from './logo.svg';
import Layout from './component/Layout';
import Page from './component/Page';
import Pagewwd from './component/Pagewwd';
import Photo from './component/Photo';
import Contact from './component/Contact';
import ReadPdf from "./component/ReadPdf";
import { BrowserRouter ,Routes, Route } from 'react-router-dom';

function App() {
  
 

  return (
    
    <BrowserRouter>
    <Routes>
   {/*  <Route path='/' element={<Dashboard />}></Route> */}
   <Route path='/' element={<Layout></Layout>}></Route>

   <Route path='About/:id' element={<Page></Page>}></Route>
   <Route path='About' element={<Page></Page>}></Route>

   <Route path='Home/:id' element={<Page></Page>}></Route>
   <Route path='Home' element={<Page></Page>}></Route>
   
   <Route path='Information' element={<Page></Page>}></Route>
   <Route path='Information/:id' element={<Page></Page>}></Route>

   <Route path='Notices' element={<Page></Page>}></Route>
   <Route path='Notices/:id' element={<Page></Page>}></Route>

   <Route path='Events' element={<Page></Page>}></Route>
   <Route path='Events/:id' element={<Page></Page>}></Route>

   
   <Route path='What we do' element={<Pagewwd></Pagewwd>}></Route>
   <Route path='What we do/:id' element={<Pagewwd></Pagewwd>}></Route>

   <Route path='Photo' element={<Photo></Photo>}></Route>
   <Route path='Contact' element={<Contact></Contact>}></Route>

   <Route path='Achive' element={ <ReadPdf/>}></Route>

  
  
   </Routes>
   </BrowserRouter>
  );
}

export default App;
