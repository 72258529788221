import React from "react";
import Nav from "./nav";
import Footer from "./Footer";
import WhatweDo from "./WhatweDo";
import Misson from "./Misson";
import Blog from "./Blog";
import Meet from "./Meet";
import Slider from "./Slider";
import NavCard from "./Navcard";
import WhatweDo2 from "./Whatwedo2";
import Notice from "./Notice";
import VideoSlider from "./Videoslider";
import BlogSlider from "./BlogSlider";




const Layout = () => {
    const pdfUrl = 'path/to/your/pdf/file.pdf';

    return (
        <>
         <div className="header_section">
            <Nav></Nav>
            <VideoSlider></VideoSlider>
           {/*  <Slider></Slider>   */}    
            </div>
            <NavCard></NavCard> 
            <Notice></Notice>
            <WhatweDo></WhatweDo>
            <Misson></Misson>
             <BlogSlider></BlogSlider> 
           {/*   <Blog></Blog>   */}
            <WhatweDo2></WhatweDo2>
            <Meet></Meet>
            <Footer></Footer>
        </>
    );
}
export default Layout;