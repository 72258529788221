import { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import Nav from "./nav";
import Footer from "./Footer";

const Page = () => {


    const [data, setData] = useState("");
    const [index, setIndex] = useState(0);
    const [PageName, setPage] = useState("");

    /*  const location = useLocation();
     const urldata=(location.pathname).split('/');  
     console.log(urldata[2]); */


    useEffect(() => {
        const currentURL = window.location.href;
        const pageName = (currentURL).split('/');
        setPage(pageName[3]);

        const url = "https://sbvmapi.uniservedata.in/api/content?pageName=" + pageName[3];
        GetData(url, pageName[4]);


    }, []);

    const searchJSON = (ApiData, id) => {
      //  console.log(ApiData);
        var indexid;
        ApiData.content_data.map((m, i) => {
          //  console.log(m.heading);
            if (m._id == (id)) {
                indexid = i;
                return i;
            } else {
                return null; // or any other value that represents no match
            }
        });
        return indexid;
    }

    const GetData = async (url, page) => {
        const data2 = await fetch(url);
        const res = await data2.json();

        if (page === undefined) { } else {                             // console.log(res.content_data);
            const id = searchJSON(res, page);
           // console.log(id);
            Getid(id);
        }
        setData(res.content_data);
    }

    const getImg = (url) => {
        const parts = url.split("\/");
        const newurl = "https://sbvmapi.uniservedata.in/image/" + parts[1];
        // console.log(newurl);
        return newurl;
    }

    const Getid = (id) => {
        setIndex(id);
    }

    return (
        <>
            <Nav></Nav>
            <div className="news_section layout_padding" style={{ "marginBottom": "2em" }}>
                <div className="container">
                    <h1>{PageName}</h1>
                    <hr></hr>
                    <div className="row">

                        <div className="gutter  col-lg-8 col-md-8" >
                            {data.length > 0 ?
                                (
                                    <>
                                   
                                        {data[index].img ?
                                            (
                                                <div className="image-container">
                                                    <img src={getImg(data[index].img)}></img>
                                                </div>) : (<></>)
                                        }
                                        <br></br>
                                        <br></br>
                                        <h2>{data[index].heading}</h2>
                                        <hr></hr>
                                        <p dangerouslySetInnerHTML={{ __html: data[index].textData }}></p>
                                        </>
                                ) : (<></>)
                            }
                        </div>
                        <div className="vr"></div>
                        <div className="gutter  col-lg-4 col-md-4 quicklink" style={{ "border-left": "2px solid #c8c8c8" }}  >
                            <h1>Quick Links</h1>
                            <hr></hr>
                            {data.length > 0 && data.map((i, m) =>
                                <>
                               { i.nameType !="What We Do" && <>
                                    <p className="text" onClick={() => Getid(m)}>{i.heading}</p>
                            </> } </>
                            )}
                        </div>
                    </div>
                </div>
            </div>


            <Footer ></Footer>
        </>
    );
}

export default Page;